<template>
  <van-cell-group inset
    v-for="item in order.goods"
    :key="item"
  >
    <van-card
      :price="item.discount ? formatPrice(item.price-item.discount, false) : formatPrice(item.price, false)"
      :desc="item.summary"
      :title="item.name"
      :thumb="item.picture"
      :origin-price="item.discount ? formatPrice(item.price, false) : null"
    >
      <template #num>
        <van-stepper :name="item.id" v-model="item.num" integer />
      </template>
    </van-card>
    <van-cell title="服务" :value="item.refundDesc" />
    <van-cell title="配送方式">
      <template #default>
        <van-radio-group v-model="item.delivery">
          <van-radio v-for="delivery in item.deliveryRefs" :key="delivery.deliveryId" :name="delivery.delivery.id">{{ delivery.delivery.name }}</van-radio>
        </van-radio-group>
      </template>
    </van-cell>
    <van-cell title="优惠金额" v-if="item.discountFee" :value="formatPrice(item.discountFee * item.num)" />
    <van-field
      v-if="item.isPromcode == 1"
      v-model="item.promcode"
      label="推广码"
      maxlength="6"
      placeholder="请输入推广码"
      input-align="right"
      error-message-align="right"
      :readonly="item.discountFee ? true : false"
      clearable
    >
      <template #button>
        <van-button size="mini" type="primary" v-if="!item.discountFee" @click="checkPromcode(item.id)">使用</van-button>
        <van-button size="mini" type="primary" v-if="item.discountFee" @click="deletePromcode(item.id)">不使用</van-button>
      </template>
    </van-field>
  </van-cell-group>

  <van-cell-group inset v-if="renewInsuranceCardColumns.length > 1">
    <van-field
      v-model="renewInsuranceCardTxt"
      is-link
      readonly
      name="renewCardPicker"
      label="续费卡"
      placeholder="点击选择健康卡"
      @click="showRenewInsuranceCardPicker"
    />
    <van-popup v-model:show="renewInsuranceCardPickerShow" position="bottom">
      <van-picker
        :columns="renewInsuranceCardColumns"
        :columns-field-names="customFieldName"
        @confirm="onConfirmRenewInsuranceCard"
        @cancel="renewInsuranceCardPickerShow = false"
      />
    </van-popup>
  </van-cell-group>

  <van-cell-group inset>
    <van-cell title="商品总价" :value="formatPrice(totalPrice)" />
    <van-cell title="商品优惠" :value="formatPrice(discountFee)" />
    <van-cell title="权益优惠" :value="formatPrice(insuranceFee)" />
    <van-cell title="配送费用" :value="formatPrice(expressFee)" />
  </van-cell-group>

  <van-cell-group inset>
    <van-field
      v-if="isPhone"
      v-model="logistics.phone"
      required
      type="tel"
      label="手机号码"
      placeholder="请输入手机号码"
      input-align="right"
      :error-message="errorMsg.phone"
      error-message-align="right"
      clearable
    />
    <van-field
      v-if="isEmail"
      v-model="logistics.email"
      required
      type="email"
      label="电子邮箱"
      placeholder="请输入邮箱地址"
      input-align="right"
      :error-message="errorMsg.email"
      error-message-align="right"
      clearable
    />
  </van-cell-group>

  <!-- <van-cell-group inset>
    <van-field
      v-model="insuranceCardTxt"
      is-link
      readonly
      name="cardPicker"
      label="健康卡"
      placeholder="点击选择健康卡"
      @click="showInsuranceCardPicker"
    />
    <van-popup v-model:show="insuranceCardPickerShow" position="bottom">
      <van-picker
        :columns="insuranceCardColumns"
        :columns-field-names="customFieldName"
        @confirm="onConfirmInsuranceCard"
        @cancel="insuranceCardPickerShow = false"
      />
    </van-popup>
  </van-cell-group> -->

  <van-cell-group inset v-if="isAddress">
    <van-field
      v-model="logistics.receiver"
      required
      type="text"
      label="姓名"
      placeholder="姓名"
      input-align="right"
      :error-message="errorMsg.receiver"
      error-message-align="right"
      clearable
    />
    <van-field
      v-model="logistics.phone"
      required
      type="tel"
      label="电话"
      placeholder="电话"
      input-align="right"
      :error-message="errorMsg.phone"
      error-message-align="right"
      clearable
    />
    <van-field
      v-model="logistics.address"
      required
      type="text"
      label="详细地址"
      placeholder="详细地址"
      input-align="right"
      :error-message="errorMsg.address"
      error-message-align="right"
      clearable
    />
    <van-field
      v-model="logistics.postalcode"
      required
      type="digit"
      label="邮政编码"
      placeholder="邮政编码"
      input-align="right"
      :error-message="errorMsg.postalcode"
      error-message-align="right"
      clearable
    />
  </van-cell-group>

  <van-submit-bar :price="totalAmount" button-text="提交订单" @submit="onSubmit" />
</template>

<script>
import { Toast, Dialog } from 'vant'

export default {
  data() {
    return {
      order: {
        goods: []
      },
      logistics: {
        phone: "",
        email: "",
        receiver: "",
        address: "",
        postalcode: "",
      },
      errorMsg: {},
      insuranceCard: '',
      insuranceCardTxt: '',
      insuranceCardColumns: [],
      insuranceCardPickerShow: false,
      renewInsuranceCard: '',
      renewInsuranceCardTxt: '',
      renewInsuranceCardColumns: [],
      renewInsuranceCardPickerShow: false,
      customFieldName: {
        text: 'card_no',
      }
    }
  },
  
  created() {
    let order = JSON.parse(localStorage.getItem('order'))
    console.log('order', order)
    if (order.length > 0) {
      var arr = []
      order.forEach(ele => {
        console.log(ele.goods, ele.num)
        arr.push(ele.goods)
      })
      var ids = arr.join()
      this.axios
        .post('/api/mall/loadGoodsListByIds', {ids: ids})
        .then(res=> {
          console.log('loadGoodsListByIds', res.data)
          if (res.data.success) {
            let goods = res.data.data.list
            goods.forEach(ele => {
              order.forEach(val => {
                if (ele.id == val.goods) ele.num = val.num
              })
              // 设置每个商品的参数默认值
              ele.delivery = ele.deliveryRefs[0].delivery.id

              if (ele.categoryId == 4) {
                // 加载待续费健康卡
                this.axios
                  .post('/api/mall/getUserCardList', {productId: ele.productRef.productId})
                  .then(res=> {
                    console.log('加载待续费健康卡', res.data)
                    if (res.data.success) {
                      this.renewInsuranceCardColumns = res.data.data.insuranceCardList
                      this.renewInsuranceCard = res.data.data.insuranceCardList[0]
                      this.renewInsuranceCardTxt = res.data.data.insuranceCardList[0].card_no
                    }
                  })
              }

            });
            this.order.goods = goods
          }
        })
      
      // 加载健康卡
      this.axios
        .post('/api/mall/getUserCardList')
        .then(res=> {
          console.log('getUserCardList', res.data)
          if (res.data.success) {
            this.insuranceCardColumns = res.data.data.insuranceCardList
          }
        })
    }
  },

  computed: {
    discountFee() {
      return this.order.goods.reduce((total, item) => item.discountFee ? total + item.discountFee * item.num : total, 0)
    },
    insuranceFee() {
      return this.order.goods.reduce((total, item) => item.insuranceFee ? total + item.insuranceFee : total, 0)
    },
    totalPrice() {
      return this.order.goods.reduce((total, item) => total + item.price * item.num, 0)
    },
    expressFee() {
      return this.order.goods.reduce((total, item) => item.deliveryRefs.length > 0 ? total + item.deliveryRefs[0].delivery.fee : total, 0)
    },
    totalAmount() {
      return this.expressFee + this.totalPrice - this.discountFee - this.insuranceFee
    },
    isPhone() {
      return this.order.goods.filter(obj => obj.delivery == 1).length > 0 ? true : false;
    },
    isEmail() {
      return this.order.goods.filter(obj => obj.delivery == 2).length > 0 ? true : false;
    },
    isAddress() {
      console.log('isAddress', this.order.goods)
      return this.order.goods.filter(obj => obj.delivery == 3 || obj.delivery == 4).length > 0 ? true : false;
    }
  },

  methods: {
    formatPrice(price, isMark) {
      if (isMark == null) {
        isMark = true
      }
      return (isMark ? "￥" : '') + (price / 100).toFixed(2)
    },

    // 待续费健康卡选择
    showRenewInsuranceCardPicker() {
      if (this.renewInsuranceCardColumns.length > 0) {
        this.renewInsuranceCardPickerShow = true
      }
    },
    onConfirmRenewInsuranceCard(value) {
      console.log('onConfirmRenewInsuranceCard', value)
      this.renewInsuranceCard = value
      this.renewInsuranceCardTxt = value.card_no
      this.renewInsuranceCardPickerShow = false
    },

    // 健康卡选择
    showInsuranceCardPicker() {
      if (this.insuranceCardColumns.length > 0) {
        this.insuranceCardPickerShow = true
      }
    },
    onConfirmInsuranceCard(value) {
      console.log('onConfirmInsuranceCard', value)
      this.insuranceCard = value
      this.insuranceCardTxt = value.prod_name + '（' + value.valid_date_str +')'
      this.insuranceCardPickerShow = false
      this.queryInsurancePrivilege()
    },

    checkPromcode(goodsId) {
      Toast.loading({ forbidClick: true })
      var promcode = this.order.goods.filter((n) => { return n.id == goodsId })[0].promcode
      console.log("checkPromcode", goodsId, promcode)

      if (promcode.length != 6) {
        Toast("推广码格式错误")
        return
      }

      this.axios
        .post('/api/mall/checkPromcode', {
          goodsId: goodsId,
          promcode: promcode
        })
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            console.log(res.data.data)
            this.order.goods.forEach(ele => {
              if (ele.id == goodsId) ele.discountFee = res.data.data.discount
            })
          } else {
            Dialog({ message: res.data.message })
            this.deletePromcode(goodsId)
          }
        })
      Toast.clear()
    },

    deletePromcode(goodsId) {
      this.order.goods.forEach(ele => {
        if (ele.id == goodsId) {
          ele.discountFee = 0
          ele.promcode = ''
        }
      })
    },

    onSubmit(value, detail) {
      Toast.loading({ forbidClick: true })

      if (!this.checkItem()) {
        Toast.clear()
        return
      }

      let goods = []
      this.order.goods.forEach(ele => {
        goods.push({
          id: ele.id,
          num: ele.num,
          promcode: ele.promcode,
          delivery: ele.delivery
        })
      })

      // console.log("goods", goods)
      // console.log("logistics", this.logistics)
      // console.log("insuranceCard", this.insuranceCard)
      let params = {
        goods: goods,
        logistics: this.logistics,
        insuranceCard: this.insuranceCard,
        expand: {}
      }
      if (this.renewInsuranceCard) {
        params.expand = { renewInsuranceCard: [ { goods: goods[0], card_id: this.renewInsuranceCard.id } ] }
      }
      this.axios
        .post('/api/mall/replaceOrder', params)
        .then(res=> {
          console.log(res.data.data.orderId)
          if (res.data.success) {
            this.$router.push({ name: 'payment', params: { orderId: res.data.data.orderId } })
          } else {
            Dialog({ message: res.data.message })
          }
        })
      Toast.clear()
    },

    checkItem() {
      // 短信
      if(this.order.goods.filter(obj => obj.delivery == 1).length > 0) {
        var reg = /^[1]([3-9])[0-9]{9}$/
        if (!reg.test(this.logistics.phone)) {
          this.errorMsg.phone = "手机号格式错误"
          return false
        } else {
          this.errorMsg.phone = ""
        }
      }
      // 邮件
      if(this.order.goods.filter(obj => obj.delivery == 2).length > 0) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if (!reg.test(this.logistics.email)) {
          this.errorMsg.email = "邮箱地址格式错误"
          return false
        } else {
          this.errorMsg.email = ""
        }
      }
      // 快递
      if(this.order.goods.filter(obj => obj.delivery == 3 || obj.delivery == 4).length > 0) {
        // 姓名
        if (Object.keys(this.logistics.receiver).length == 0) {
          this.errorMsg.receiver = "姓名不能为空"
          return false
        } else {
          this.errorMsg.receiver = ""
        }
        // 电话
        var regPhone = /^[1]([3-9])[0-9]{9}$/
        if (!regPhone.test(this.logistics.phone)) {
          this.errorMsg.phone = "手机号格式错误"
          return false
        } else {
          this.errorMsg.phone = ""
        }
        // 地址
        if (Object.keys(this.logistics.address).length == 0) {
          this.errorMsg.address = "收货地址不能为空"
          return false
        } else {
          this.errorMsg.address = ""
        }
        // 邮编
        var regPostalcode = /^[1-9]\d{5}$/
        if (!regPostalcode.test(this.logistics.postalcode)) {
          this.errorMsg.postalcode = "邮政编码格式错误"
          return false
        } else {
          this.errorMsg.postalcode = ""
        }
      }
      return true
    },

    // 计算健康卡权益
    queryInsurancePrivilege() {
      let goods = []
      this.order.goods.forEach(ele => {
        goods.push({
          id: ele.id,
          num: ele.num
        })
      })
      this.axios
        .post('/api/mall/queryInsurancePrivilege', {
          goods: goods,
          insuranceCard: this.insuranceCard
        })
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            this.order.goods.forEach(ele => {
              res.data.data.insurance.forEach(val => {
                if (ele.id == val.id) ele.insuranceFee = val.insurance
              })
            })
          } else {
            Dialog({ message: res.data.message })
          }
        })
    }
  },
}
</script>

<style lang="less">
.van-cell-group {
  margin-top: 2%;

  .van-radio {
    margin-bottom: .5rem;
  }
}
</style>